import React from "react"
import Layout from "../components/layout.en"
import "./about.css"
import HomePageYAMLData from "../../content/home.en.yaml"

export default (props) => {
  return (
    <Layout pageId={"/en/about"}>
      <div className='w-100 about_banner'>
        <div className='container  h-100'>
          <div className='row align-items-center h-100 '>
            <div className='col-12'>
              <div className='col-auto px-0' style={{ fontSize: 70, fontWeight: "bold", color: "#FFFFFF" }}>
                {HomePageYAMLData.about_title}
              </div>
              <div className='w-100'/>
              <div>
                <div style={{ backgroundColor: "rgba(69, 69, 69, 1)", height: 2, width: 196, marginTop: 10 }}/>
              </div>
              <div className='w-100'/>
              <div className='col-9' style={{
                fontSize: 18,
                lineHeight: "30px",
                marginTop: 15,
                backgroundColor: "rgba(15, 67, 151, 0.24)",
              }}>
                <div style={{
                  padding: 25,
                  color: "#fff",
                  textAlign:'justify'
                }}>
                  {HomePageYAMLData.about_title_content}
                  {/*我们实验室有多名经验丰富的临床专家和放射影像专家，均有多年的临床工作经验，以及拥有强大的技术支持，能准确判读MRI、HRMRI、DSA和血管内OCT等影像，给出标准规范的报告结果，有助于科研成果产出和产品研发。*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className='row align-items-center justify-content-center'>
          <div className='col-auto'>
            <div className={"sub_title"}>
              {HomePageYAMLData.about_category_title_1}
              {/*团队分工及标准化流程*/}
            </div>
          </div>
        </div>

        <div className={"row align-items-center justify-content-center"} style={{backgroundColor: '#f4f3f3'}} >
          <div className={"col-12"}>
            <div className={'container'}>
              <div className={"row align-items-center justify-content-center"} >
                <div className={'col-12'}>
                  <img src={"/images/about/3.png"} className='img-fluid w-100'/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row align-items-center justify-content-center'>
          <div className='col-auto'>
            <div className={"sub_title"}>
              {HomePageYAMLData.about_category_title_2}
              {/*组织架构*/}
            </div>
          </div>
        </div>
        <div className={"row align-items-center justify-content-center"} style={{backgroundColor: '#f4f3f3'}}>
          <div className={"col-12"}>
            <div className={'container'}>
              <div className={"row align-items-center justify-content-center"} >
                <div className={'col-12'}>
                  <img src={"/images/about/4.png"} className='img-fluid w-100'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-auto'>
            <div className={"sub_title"}>
              {HomePageYAMLData.about_category_title_3}
              {/*服务对象*/}
            </div>
          </div>
        </div>
        <div className={"row px-0"}>
          <div className={"col-lg-4 col-12 px-2"}>
            <div  className='img_shadow'>
              <img src={"/images/about/VCG211223585322.png"} className='img-fluid w-100'/>
              <div className='about-item'>
                <div className='about-item-title'>
                  {HomePageYAMLData.about_category_title_3_1}
                  {/*主要研究者（PI）*/}
                </div>
                <div className='about-item-msg'>
                  {HomePageYAMLData.about_category_title_3_1_content}
                  {/*承接多中心或单中心临床科研项目*/}
                </div>
              </div>
            </div>
          </div>

          <div className={"col-lg-4 col-12 px-2"}>
            <div  className='img_shadow'>
              <img src={"/images/about/VCG41184974247.png"} className='img-fluid w-100'/>
              <div className='about-item'>
                <div className='about-item-title'>
                  {HomePageYAMLData.about_category_title_3_2}
                  {/*公司*/}
                </div>
                <div className='about-item-msg'>
                  {HomePageYAMLData.about_category_title_3_2_content}
                  {/*承接上市前和上市后产品研究项目*/}
                </div>
              </div>
            </div>
          </div>

          <div className={"col-lg-4 col-12 px-2"}>
            <div className='img_shadow'>
              <img src={"/images/about/VCG211264413136.png"} className='img-fluid w-100'/>
              <div className='about-item'>
                <div className='about-item-title'>
                  {HomePageYAMLData.about_category_title_3_3}
                  {/*临床医师*/}
                </div>
                <div className='about-item-msg' >
                  {HomePageYAMLData.about_category_title_3_3_content}
                  {/*承接个人临床科研需求项目，辅助影像数据库的构建、管理和判读*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"w-100"} style={{ height: 100 }}/>
      </div>
    </Layout>
  )
}
